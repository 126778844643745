import homeData from "../assets/data/home.json";
import manchesterUnited from "../assets/data/manu.json";
import manchesterCity from "../assets/data/mancity.json";
import liverpool from "../assets/data/liverpool.json";

let allData = [homeData, manchesterUnited, manchesterCity, liverpool];

export const fetchGameInfo = (gameId) => {
  let desiredGameInfo = allData.find((dt) => dt.id === gameId);

  return desiredGameInfo;
};
