// modules
import React, { useEffect, useState } from "react";
import "./HowToPlay.css";
import { LEAGUES } from "../../utils/constants";

// context
import useGame from "../../context/game";

// components
import ModalWrapper from "../Modal";
import { Cell } from "../Game/Grid";

const HowToPlay = ({ show, onClose }) => {
  const { mainWord, currentGame } = useGame();
  const [isRevealing, setIsRevealing] = useState(false);

  useEffect(() => {
    if (show) {
      setIsRevealing(true);
    } else {
      setIsRevealing(false);
    }
  }, [show]);

  if (!mainWord) return;
  return (
    <ModalWrapper show={show} onClose={onClose}>
      <div className="game-guide_content">
        <h1 className="my-6 text-lg font-bold">
          {currentGame.id === LEAGUES["manchester-united"]
            ? "Welcome to Manchester United Wordle!"
            : currentGame.id === LEAGUES["manchester-city"]
            ? "Welcome to Manchester City Wordle!"
            : currentGame.id === LEAGUES["liverpool"]
            ? "Welcome to Liverpool FC Wordle!"
            : "Welcome to the new Football Wordle!"}
        </h1>
        <article>
          <p className="text-sm text-center">
            {currentGame.id === LEAGUES["manchester-united"]
              ? "There will be a new Manchester United player name of a different length every day. Each player will have played 25 games or more for Manchester United since 1980. Press the enter button to submit your answer. After each guess, the colour of the tiles will change to show which letters if any you got correct. Green for a correct position and orange for an incorrect letter position."
              : currentGame.id === LEAGUES["manchester-city"]
              ? "There will be a new Manchester City player name of a different length every day. Each player will have played 25 games or more for Manchester City since 1980. Press the enter button to submit your answer. After each guess, the colour of the tiles will change to show which letters if any you got correct. Green for a correct position and orange for an incorrect letter position."
              : currentGame.id === LEAGUES["liverpool"]
              ? "There will be a new Liverpool player name of a different length every day. Each player will have played 25 games or more for Liverpool since 1980. Press the enter button to submit your answer. After each guess, the colour of the tiles will change to show which letters if any you got correct. Green for a correct position and orange for an incorrect letter position."
              : "There will be a new player name of a different length every day. Each guess must be a player from the top 5 leagues. Press the enter button to submit your answer. After each guess, the colour of the tiles will change to show which letters if any you got correct. Green for a correct position and orange for an incorrect letter position."}
          </p>

          <div className="flex justify-center mb-1 mt-4">
            {currentGame.id === LEAGUES["manchester-united"] ? (
              <>
                <Cell
                  isRevealing={isRevealing}
                  isCompleted={true}
                  value="B"
                  status="correct"
                />
                <Cell value="R" />
                <Cell value="U" />
                <Cell value="C" />
                <Cell value="E" />
              </>
            ) : currentGame.id === LEAGUES["manchester-city"] ? (
              <>
                <Cell
                  isRevealing={isRevealing}
                  isCompleted={true}
                  value="G"
                  status="correct"
                />
                <Cell value="I" />
                <Cell value="V" />
                <Cell value="E" />
                <Cell value="N" />
              </>
            ) : currentGame.id === LEAGUES["liverpool"] ? (
              <>
                <Cell
                  isRevealing={isRevealing}
                  isCompleted={true}
                  value="D"
                  status="correct"
                />
                <Cell value="I" />
                <Cell value="O" />
                <Cell value="U" />
                <Cell value="F" />
              </>
            ) : (
              <>
                <Cell
                  isRevealing={isRevealing}
                  isCompleted={true}
                  value="S"
                  status="correct"
                />
                <Cell value="A" />
                <Cell value="L" />
                <Cell value="A" />
                <Cell value="H" />
              </>
            )}
          </div>

          <p className="text-sm text-center">
            {currentGame.id === LEAGUES["manchester-united"]
              ? "The letter B is in the word and in the correct spot."
              : currentGame.id === LEAGUES["manchester-city"]
              ? "The letter G is in the word and in the correct spot."
              : currentGame.id === LEAGUES["liverpool"]
              ? "The letter D is in the word and in the correct spot"
              : "The letter S is in the word and in the correct spot."}
          </p>

          <div className="flex justify-center mb-1 mt-4">
            {currentGame.id === LEAGUES["manchester-city"] ? (
              <>
                <Cell value="T" />
                <Cell value="E" />
                <Cell
                  isRevealing={isRevealing}
                  isCompleted={true}
                  value="V"
                  status="present"
                />
                <Cell value="E" />
                <Cell value="Z" />
              </>
            ) : currentGame.id === LEAGUES["liverpool"] ? (
              <>
                <Cell value="R" />
                <Cell value="E" />
                <Cell
                  isRevealing={isRevealing}
                  isCompleted={true}
                  value="I"
                  status="present"
                />
                <Cell value="N" />
                <Cell value="A" />
              </>
            ) : (
              <>
                <Cell value="P" />
                <Cell value="O" />
                <Cell
                  isRevealing={isRevealing}
                  isCompleted={true}
                  value="G"
                  status="present"
                />
                <Cell value="B" />
                <Cell value="A" />
              </>
            )}
          </div>
          <p className="text-sm text-center">
            {currentGame.id === LEAGUES["manchester-city"]
              ? "The letter V is in the word but in the wrong spot."
              : currentGame.id === LEAGUES["liverpool"]
              ? "The letter I is in the word but in the wrong spot."
              : "The letter G is in the word but in the wrong spot."}
          </p>

          <div className="flex justify-center mb-1 mt-4">
            {currentGame.id === LEAGUES["manchester-city"] ? (
              <>
                <Cell value="F" />
                <Cell value="O" />
                <Cell value="D" />
                <Cell
                  isRevealing={isRevealing}
                  isCompleted={true}
                  value="E"
                  status="absent"
                />
                <Cell value="N" />
              </>
            ) : currentGame.id === LEAGUES["liverpool"] ? (
              <>
                <Cell value="S" />
                <Cell value="A" />
                <Cell value="L" />
                <Cell
                  isRevealing={isRevealing}
                  isCompleted={true}
                  value="A"
                  status="absent"
                />
                <Cell value="H" />
              </>
            ) : (
              <>
                <Cell value="D" />
                <Cell value="A" />
                <Cell value="L" />
                <Cell
                  isRevealing={isRevealing}
                  isCompleted={true}
                  value="O"
                  status="absent"
                />
                <Cell value="T" />
              </>
            )}
          </div>
          <p className="text-sm text-center">
            {currentGame.id === LEAGUES["manchester-city"]
              ? "The letter E is not in the word in any spot."
              : currentGame.id === LEAGUES["liverpool"]
              ? "The letter A is not in the word in any spot."
              : "The letter O is not in the word in any spot."}
          </p>
        </article>
      </div>
    </ModalWrapper>
  );
};

export default HowToPlay;
