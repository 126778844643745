import React, { useRef, useEffect } from "react";

function Ad({ placementId, className, desktop }) {
  const adRef = useRef(null);

  useEffect(() => {
    handleResizeAndInitialize(null, true);

    window.addEventListener("resize", handleResizeAndInitialize);

    return () => {
      handleDestroy();
      window.removeEventListener("resize", handleResizeAndInitialize);
    };
  }, []);

  function handleResizeAndInitialize(e, isInit = false) {
    let innerWidth = window.innerWidth;

    if (desktop) {
      if (innerWidth >= 1024) {
        if (
          (window.__vm_add && !window.__vm_add.includes(adRef.current)) ||
          isInit
        ) {
          handleInitialize();
        }
      } else {
        handleDestroy();
      }
    } else {
      if (innerWidth < 1024) {
        if (
          (window.__vm_add && !window.__vm_add.includes(adRef.current)) ||
          isInit
        ) {
          handleInitialize();
        }
      } else {
        handleDestroy();
      }
    }
  }

  const handleInitialize = () => {
    console.log("mounting", adRef.current);
    window.__vm_add = window.__vm_add || [];
    window.__vm_add.push(adRef.current);
  };

  const handleDestroy = () => {
    console.log("unmounting", adRef.current);
    window.__vm_remove = window.__vm_remove || [];
    window.__vm_remove.push(adRef.current);
  };

  if (!placementId) {
    return null;
  }

  return (
    <div>
      <div
        className={`vm-placement ${className}`}
        data-id={placementId}
        ref={adRef}
      ></div>
    </div>
  );
}

export default Ad;
