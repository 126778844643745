import React from "react";

function Timer({ timeSec, isGamePage }) {
  const formatTimer = (time) => {
    if (!time) {
      return (
        <>
          <span className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold">
            0
          </span>
          <span className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold">
            0
          </span>
          <span className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold">
            :
          </span>
          <span className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold">
            0
          </span>
          <span className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold">
            0
          </span>
        </>
      );
    }

    const minutes = Math.floor((time / 60) % 60)
      .toFixed(0)
      .padStart(2, "0");
    const seconds = (time % 60).toFixed(0).padStart(2, "0");
    if (time / 60 >= 60) {
      const hours = Math.floor(time / 60 / 60)
        .toFixed(0)
        .padStart(2, "0");

      return (
        <>
          {hours.split("").map((int, idx) => (
            <span
              key={idx}
              className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold"
            >
              {int}
            </span>
          ))}
          <span className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold">
            :
          </span>
          {minutes.split("").map((int, idx) => (
            <span
              key={idx}
              className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold"
            >
              {int}
            </span>
          ))}
          <span className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold">
            :
          </span>
          {seconds.split("").map((int, idx) => (
            <span
              key={idx}
              className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold"
            >
              {int}
            </span>
          ))}
        </>
      );
    }

    return (
      <>
        {minutes.split("").map((int, idx) => (
          <span
            key={idx}
            className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold"
          >
            {int}
          </span>
        ))}
        <span className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold">
          :
        </span>
        {seconds.split("").map((int, idx) => (
          <span
            key={idx}
            className="inline-block w-3 md:w-4 text-base md:text-lg text-center font-semibold"
          >
            {int}
          </span>
        ))}
      </>
    );
  };

  return (
    <div
      className={`absolute ${
        isGamePage ? "game_timer" : "top-[2rem]"
      } right-0 mr-2 text-gray-100`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="inline-block mr-2 w-5 h-5 md:h-6 md:w-6 -mt-1"
      >
        <path
          fillRule="evenodd"
          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
          clipRule="evenodd"
        />
      </svg>

      {formatTimer(timeSec)}
    </div>
  );
}

export default Timer;
