// modules
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { unicodeLength, unicodeSplit } from "../../libs/words";
import { MAX_CHALLENGES, REVEAL_TIME_MS } from "../../utils/config";
import { CalendarRightIcon, CalendarLeftIcon } from "../../assets/Icons";
import "./Game.css";

// context
import useGame from "../../context/game";
import useAlert from "../../context/alert";

// components
import Keyboard from "./Keyboard";
import Grid from "./Grid";
import Loader from "../Loader/Loader";
import Challenge from "../Challenge/Challenge";
import Timer from "./Timer";

function GameMainBox({ isGamePage }) {
  const {
    mainWord,
    isLoading,
    guessList,
    addToGuessList,
    gameOver,
    currentGame,
    lastGameNo,
    gameNo,
    gameWinLose,
    gameTimer,
    handleTimer,
    setShowStats,
    lastGame,
  } = useGame();

  const {
    setShow: setAlertShow,
    setMessage: setAlertMessage,
    setVariant: setAlertVariant,
    setKeep: setAlertStay,
    setIsGamePage,
  } = useAlert();

  const [startTimer, setStartTimer] = useState(false);

  const [isRevealing, setIsRevealing] = useState(false);
  const [currentRowClass, setCurrentRowClass] = useState("");
  const [currentGuess, setCurrentGuess] = useState("");

  const [next, setNext] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setCurrentGuess("");
    setStartTimer(false);
    setAlertShow(false);
  }, [location, setAlertShow]);

  useEffect(() => {
    let _next = lastGameNo + 2;
    if (_next <= gameNo) {
      setNext(_next);
    } else {
      setNext(null);
    }
  }, [lastGameNo, gameNo]);

  useEffect(() => {
    let timeOut;
    let timeOutStats;
    if (gameOver && gameWinLose.win) {
      timeOut = setTimeout(
        () => {
          setAlertMessage("Great!");
          setAlertVariant("success");
          setAlertShow(true);
          setAlertStay(true);
          setIsGamePage(isGamePage);
        },
        isRevealing ? REVEAL_TIME_MS * mainWord.length : 0
      );
      if (!lastGame) {
        timeOutStats = setTimeout(
          () => {
            setShowStats(true);
          },
          isRevealing
            ? REVEAL_TIME_MS * (mainWord.length + 4)
            : REVEAL_TIME_MS * 4
        );
      }
    } else if (gameOver && gameWinLose.lose) {
      timeOut = setTimeout(
        () => {
          setAlertMessage("Hard luck! The player was " + mainWord);
          setAlertVariant("error");
          setAlertShow(true);
          setAlertStay(true);
          setIsGamePage(isGamePage);
        },
        isRevealing ? REVEAL_TIME_MS * mainWord.length : 0
      );

      if (!lastGame) {
        timeOutStats = setTimeout(
          () => {
            setShowStats(true);
          },
          isRevealing
            ? REVEAL_TIME_MS * (mainWord.length + 4)
            : REVEAL_TIME_MS * 4
        );
      }
    }

    return () => {
      clearTimeout(timeOut);
      clearTimeout(timeOutStats);
    };

    // eslint-disable-next-line
  }, [gameWinLose, gameOver, mainWord]);

  useEffect(() => {
    let interval;
    let timerRunning = (startTimer || guessList.length > 0) && !gameOver;

    if (timerRunning) {
      interval = setInterval(() => {
        if (gameTimer === null) {
          handleTimer(1, currentGame?.id);
        } else {
          handleTimer(gameTimer + 1, currentGame?.id);
        }
      }, 1000);
    }

    return () => {
      if (timerRunning) clearInterval(interval);
    };
  }, [startTimer, gameTimer, gameOver, guessList, currentGame, handleTimer]);

  const clearCurrentRowClass = () => setCurrentRowClass("");

  const onChar = (value) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= mainWord.length &&
      guessList.length < MAX_CHALLENGES &&
      !gameOver
    ) {
      setCurrentGuess(`${currentGuess}${value}`);
      if (!startTimer) {
        setStartTimer(true);
      }
    }
  };

  const onDelete = () => {
    setCurrentGuess(unicodeSplit(currentGuess).slice(0, -1).join(""));
  };

  const onEnter = () => {
    if (gameOver) {
      return;
    }

    setAlertShow(false);

    if (!(unicodeLength(currentGuess) === mainWord.length)) {
      setCurrentRowClass("jiggle-row");
      setAlertMessage("Not enough letters");
      setAlertVariant("error");
      setAlertShow(true);
      setAlertStay(false);
      setIsGamePage(isGamePage);

      setTimeout(() => {
        clearCurrentRowClass();
      }, 250);
      return;
    }

    let isWordInWordList = (player) => {
      return currentGame.players.find(
        (dt) => dt.toUpperCase() === player.toUpperCase()
      );
    };

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass("jiggle-row");
      setAlertMessage("Player not found");
      setAlertVariant("error");
      setAlertShow(true);
      setAlertStay(false);
      setIsGamePage(isGamePage);

      setTimeout(() => {
        clearCurrentRowClass();
      }, 250);
      return;
    }

    addToGuessList(currentGuess);
    setCurrentGuess("");
    setIsRevealing(true);
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false);
    }, REVEAL_TIME_MS * mainWord.length);
  };

  if (isLoading || !mainWord) {
    return <Loader />;
  }

  return (
    <>
      <Timer isGamePage={isGamePage} timeSec={gameTimer} />
      <div className="flex items-center justify-center mt-10 md:mt-0 mb-2 md:space-x-20">
        <Link
          to={`?game=${gameNo && lastGameNo}`}
          className={`game-time_machine yesterday-btn ${
            !lastGameNo ? "invisible" : ""
          }`}
        >
          <CalendarLeftIcon />
        </Link>

        <Challenge />

        <Link
          to={next === gameNo ? location.pathname : `?game=${gameNo && next}`}
          className={`game-time_machine today-btn ${!next ? "invisible" : ""}`}
        >
          <CalendarRightIcon />
        </Link>
      </div>
      <Grid
        guesses={guessList}
        currentGuess={currentGuess}
        isRevealing={isRevealing}
        currentRowClass={currentRowClass}
      />
      <ClueBox />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        isRevealing={isRevealing}
      />
    </>
  );
}

const ClueBox = () => {
  const {
    clueCount,
    currentGame,
    giveClue,
    mainWord,
    guessList,
    gameOver,
    handleGiveup,
    guessDistribution,
  } = useGame();
  const splitSolution = unicodeSplit(mainWord);
  const [clueLetter, setClueLetter] = useState({});

  useEffect(() => {
    if (clueCount > 0) {
      const charObj = {};
      let i = 0,
        endLoop = false;

      guessDistribution.forEach((word) => {
        unicodeSplit(word).forEach((letter, i) => {
          if (!splitSolution.includes(letter)) {
            // make status absent
            return (charObj[letter] = "absent");
          }

          if (letter === splitSolution[i]) {
            //make status correct
            return (charObj[letter] = "correct");
          }

          if (charObj[letter] !== "correct") {
            //make status present
            return (charObj[letter] = "present");
          }
        });
      });

      if (
        !charObj[splitSolution[splitSolution.length - 1]] ||
        charObj[splitSolution[splitSolution.length - 1]] !== "correct"
      ) {
        setClueLetter({
          pos: "last",
          letter: splitSolution[splitSolution.length - 1],
        });

        return;
      }

      while (i < splitSolution.length && !endLoop) {
        let currentLetter = splitSolution[i];

        if (
          !charObj[splitSolution[i]] ||
          charObj[splitSolution[i]] !== "correct"
        ) {
          switch (i) {
            case 0:
              setClueLetter({ pos: "first" });
              break;
            case 1:
              setClueLetter({ pos: "second" });
              break;
            case 2:
              setClueLetter({ pos: "third" });
              break;
            case 3:
              setClueLetter({ pos: "fourth" });
              break;
            case 4:
              if (splitSolution.length === 5) {
                setClueLetter({ pos: "last" });
              } else {
                setClueLetter({ pos: "fifth" });
              }
              break;
            case 5:
              if (splitSolution.length === 6) {
                setClueLetter({ pos: "last" });
              } else {
                setClueLetter({ pos: "sixth" });
              }
              break;
            case 6:
              setClueLetter({ pos: "last" });
              break;
            default:
              setClueLetter({});
              break;
          }

          setClueLetter((prev) => ({ ...prev, letter: currentLetter }));
          endLoop = true;
        }
        i += 1;
      }
    }
  }, []);

  const handleClue = () => {
    giveClue(currentGame.id);
    const charObj = {};
    let i = 0,
      endLoop = false;

    guessDistribution.forEach((word) => {
      unicodeSplit(word).forEach((letter, i) => {
        if (!splitSolution.includes(letter)) {
          // make status absent
          return (charObj[letter] = "absent");
        }

        if (letter === splitSolution[i]) {
          //make status correct
          return (charObj[letter] = "correct");
        }

        if (charObj[letter] !== "correct") {
          //make status present
          return (charObj[letter] = "present");
        }
      });
    });

    if (
      !charObj[splitSolution[splitSolution.length - 1]] ||
      charObj[splitSolution[splitSolution.length - 1]] !== "correct"
    ) {
      setClueLetter({
        pos: "last",
        letter: splitSolution[splitSolution.length - 1],
      });

      return;
    }

    while (i < splitSolution.length && !endLoop) {
      let currentLetter = splitSolution[i];

      if (
        !charObj[splitSolution[i]] ||
        charObj[splitSolution[i]] !== "correct"
      ) {
        switch (i) {
          case 0:
            setClueLetter({ pos: "first" });
            break;
          case 1:
            setClueLetter({ pos: "second" });
            break;
          case 2:
            setClueLetter({ pos: "third" });
            break;
          case 3:
            setClueLetter({ pos: "fourth" });
            break;
          case 4:
            if (splitSolution.length === 5) {
              setClueLetter({ pos: "last" });
            } else {
              setClueLetter({ pos: "fifth" });
            }
            break;
          case 5:
            if (splitSolution.length === 6) {
              setClueLetter({ pos: "last" });
            } else {
              setClueLetter({ pos: "sixth" });
            }
            break;
          case 6:
            setClueLetter({ pos: "last" });
            break;
          default:
            setClueLetter({});
            break;
        }

        setClueLetter((prev) => ({ ...prev, letter: currentLetter }));
        endLoop = true;
      }
      i += 1;
    }
  };

  if (!currentGame || guessList.length < 3 || gameOver) return <></>;

  return (
    <div className="flex flex-col items-center justify-center my-6">
      {clueCount > 0 && (
        <div className="text-center mb-4 text-white">
          {clueLetter.pos === "first" ? (
            <>
              {" "}
              The word starts with a{" "}
              <strong className="p-1 rounded shadowed bg-green-500 text-white border-green-500">
                {clueLetter.letter}
              </strong>
              !
            </>
          ) : clueLetter.pos === "last" ? (
            <>
              {" "}
              The word ends with a{" "}
              <strong className="p-1 rounded shadowed bg-green-500 text-white border-green-500">
                {clueLetter.letter}
              </strong>
              !
            </>
          ) : (
            <>
              The {clueLetter.pos} letter is{" "}
              <strong className="p-1 rounded shadowed bg-green-500 text-white border-green-500">
                {clueLetter.letter}
              </strong>
              !
            </>
          )}
        </div>
      )}
      <div className="flex space-x-4 items-center justify-center">
        {clueCount === 0 && (
          <button
            className="text-white bg-green-500 px-4 py-2 mx-4 rounded"
            onClick={handleClue}
          >
            Want a clue?
          </button>
        )}

        <button
          className="text-white bg-red-500 px-4 py-2 mx-4 rounded"
          onClick={handleGiveup}
        >
          Give up?
        </button>
      </div>
    </div>
  );
};

export default GameMainBox;
