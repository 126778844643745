import React from "react";
import "./Hero.css";

function Hero() {
  return (
    <article className="hero_info">
      <h1> Football Wordle Daily Game </h1>
      <p>
        Welcome to football Wordle where you get 6 guesses to find the name of
        the hidden footballer in our daily wordle game.
      </p>
      <p>
        There will be a new player with a varying amount of letters to play each
        day. The mystery player is from the top 5 leagues in Europe, The Premier
        League, La Liga, Bundesliga, Serie A and Ligue 1. Make your guess and
        press the enter button to submit your answer. After each guess, the
        colour of the tiles will change to show which letters if any you got
        correct. Green squares appear for a correct letter position and orange
        for an incorrect letter position. After 3 guesses you can get a clue and
        a letter will appear to help you.
      </p>
      <p>
        Once you have guessed the mystery player you can share your score with
        your friends and show off your football knowledge.
      </p>
    </article>
  );
}

export default Hero;
