export const LEAGUES = {
  "super-league": 679,
  "manchester-united": 680,
  "manchester-city": 681,
  liverpool: 682,
};

export const LEAGUES_LIST_NAV = [
  {
    name: "Football Wordle",
    img: require("../assets/flags/football.png"),
    class: "nav_menu-mobile_football-img",
    nested: true,
    games: [
      {
        id: 11,
        name: "World Cup",
        slug: "https://www.worldcupwordle.com/",
        img: require("../assets/flags/world.png"),
        logo: "/league-img/wc.png",
        style: { width: "62px" },
      },

      {
        id: 679,
        name: "Big 5 Leagues",
        slug: "/",
        img: require("../assets/flags/super.png"),
        logo: "/league-img/super.png",
      },
      {
        id: 680,
        name: "Man Utd",
        slug: "/game/manchester-united",
        img: "/league-img/man-u.png",
        logo: "/league-img/man-u.png",
      },
      {
        id: 681,
        name: "Man City",
        slug: "/game/manchester-city",
        img: "/league-img/man-city.png",
        logo: "/league-img/man-city.png",
      },
      {
        id: 682,
        name: "Liverpool FC",
        slug: "/game/liverpool",
        img: "/league-img/liverpool.png",
        logo: "/league-img/liverpool.png",
      },
    ],
  },
  {
    name: "Guess Who Football",
    img: require("../assets/player.png"),
    nested: true,
    games: [
      {
        id: 101,
        name: "Big 5 Leagues",
        slug: "/guesswhofootball",
        img: require("../assets/flags/super.png"),
        logo: "/league-img/super.png",
      },
      {
        id: 25,
        name: "World Cup",
        slug: "/guesswhofootball/game/world-cup",
        img: require("../assets/flags/world.png"),
        logo: "/league-img/wc.png",
        style: { width: "62px" },
      },
      {
        id: 13,
        name: "Premier League",
        slug: "/guesswhofootball/game/premier-league",
        img: require("../assets/flags/eng.png"),
        logo: "/league-img/pl.icon.png",
        logoSecondary: "/league-img/pl-secondary.icon.png",
      },
      {
        id: 14,
        name: "EFL Championship",
        slug: "/guesswhofootball/game/english-football-league",
        img: require("../assets/flags/engefl.png"),
        logo: "/league-img/efl.png",
      },
      {
        id: 53,
        name: "Laliga",
        slug: "/guesswhofootball/game/laliga",
        img: require("../assets/flags/spain.png"),
        logo: "/league-img/laliga.icon.png",
        logoSecondary: "/league-img/laliga-secondary.icon.png",
      },
      {
        id: 31,
        name: "Serie A",
        slug: "/guesswhofootball/game/series-a",
        img: require("../assets/flags/Ita.png"),
        logo: "/league-img/serie-a.png",
      },
      {
        id: 19,
        name: "Bundesliga",
        slug: "/guesswhofootball/game/bundesliga",
        img: require("../assets/flags/ger.png"),
        logo: "/league-img/bundesliga.png",
      },
      {
        id: 16,
        name: "Ligue 1",
        slug: "/guesswhofootball/game/ligue-1",
        img: require("../assets/flags/fra.png"),
        logo: "/league-img/ligue-1.png",
      },
      {
        id: 15,
        name: "Brasileirão",
        slug: "/guesswhofootball/game/brasileirão",
        img: require("../assets/flags/bra.png"),
        logo: "/league-img/brasileirao.png",
      },
    ],
  },
  {
    name: "Guess Who Football Legends",
    img: require("../assets/flags/legends.png"),
    nested: true,
    games: [
      {
        id: 100,
        name: "All Time Legends",
        slug: "/guesswhofootball/game/all_time_legends",
        img: require("../assets/flags/legends.icon.png"),
        logo: "/league-img/legends.png",
        class: "nav_menu-mobile_legendsBall",
      },
      {
        id: 1001,
        name: "Man Utd Legends",
        slug: "/guesswhofootball/game/man-united-legends",
        img: "/league-img/man-u.png",
        logo: "/league-img/man-u.png",
      },
      {
        id: 1054,
        name: "Brazilian Legends",
        slug: "/guesswhofootball/game/brazilian-legends",
        img: "/league-img/brazil.svg",
        logo: "/league-img/brazil.svg",
      },
    ],
  },
  {
    name: "Guess The Club Badge",
    slug: "/guesswhofootball/game/guessTheClub",
    img: require("../assets/flags/guesstheclub.png"),
    nested: false,
  },
  {
    name: "News",
    img: require("../assets/icons/news.jpg"),
    nested: false,
    class: "nav_menu-mobile_football-img",
    slug: "/guesswhofootball/news",
  },
];
