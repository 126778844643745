import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { isInMobileBrowser } from "./libs/browser";

import Navbar from "./layout/Navbar";
import Game from "./layout/Game";
import Footer from "./layout/Footer";
import PopUp from "./components/PopUp/PopUp";
import Hero from "./components/Hero/Hero";

import ScrollToTop from "./components/ScrollToTop";
import "./styles.css";

import Loader from "./components/Loader/Loader";
import bgImage from "./assets/field.png";
import Privacy from "./components/Privacy/Privacy";
import Ad from "./layout/Ad";

export default function App() {
  const location = useLocation();
  const [showOpenApp, setShowOpenApp] = useState(false);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/privacy-policy") {
      document.body.style.backgroundImage = `url(${bgImage})`;
      document.body.style.setProperty("--overlay-opacity", 0); // was 0.85
    } else {
      document.body.style.backgroundImage = "";
      document.body.style.setProperty("--overlay-opacity", 0.81);
    }
  }, [location]);

  useEffect(() => {
    let tmptLoader = document.getElementById("temp-loader");
    if (tmptLoader) {
      tmptLoader.parentNode.removeChild(tmptLoader);
    }
  }, []);

  useEffect(() => {
    setShowOpenApp(isInMobileBrowser);

    window.addEventListener("resize", (e) => {
      setShowOpenApp(isInMobileBrowser);
    });

    let tmptLoader = document.getElementById("temp-loader");
    if (tmptLoader) {
      tmptLoader.parentNode.removeChild(tmptLoader);
    }

    setTimeout(() => {
      window.ezstandalone = window.ezstandalone || {};
      window.ezstandalone.cmd = window.ezstandalone.cmd || [];
      window.ezstandalone.cmd.push(function () {
        window.ezstandalone.define(105, 106, 104, 107, 111, 112);
        window.ezstandalone.enable();
        window.ezstandalone.display();
      });
    }, 5000);

    return () => {
      window.removeEventListener("resize", (e) => {
        setShowOpenApp(isInMobileBrowser);
      });
    };
  }, []);

  return (
    <div id="app">
      <Navbar />
      <Loader />
      <ScrollToTop />
      <div
        className="vm-placement"
        data-id="64186ff0139dbe16fbf089d7"
        data-display-type="hybrid-banner"
      ></div>
      <div className="relative w-full">
        <div
          className="vm-placement my-4"
          id="vm-av"
          data-format="isvideo"
        ></div>
      </div>
      <div id="ezoic-pub-ad-placeholder-106"> </div>
      <div
        style={{
          position: "absolute",
          maxWidth: "320px",
          left: "2px",
          width: "100%",
        }}
      >
        <div>
          <div id="ezoic-pub-ad-placeholder-111"> </div>
        </div>
      </div>

      <Routes>
        <Route path="/game">
          <Route
            path=":gameName"
            element={
              <>
                <Game />
                <div id="ezoic-pub-ad-placeholder-104"> </div>
                <div className="relative w-full">
                  <div
                    className="vm-placement"
                    data-id="642675f41e85fb3356e14cf8"
                  ></div>
                </div>
              </>
            }
          />
        </Route>
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route
          path="/"
          element={
            <>
              <Game />
              <div id="ezoic-pub-ad-placeholder-104"> </div>
              <Hero />
              <div id="ezoic-pub-ad-placeholder-105"> </div>
              <div className="relative w-full">
                <div
                  className="vm-placement"
                  data-id="642675f41e85fb3356e14cf8"
                ></div>
              </div>
            </>
          }
        />
      </Routes>
      <div id="ezoic-pub-ad-placeholder-107"> </div>
      <Footer />
      <div className="black-glass"></div>
      <PopUp mobileDeviceInfo={showOpenApp} />
    </div>
  );
}
